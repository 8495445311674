import React from 'react';
import { useDataProvider } from 'react-admin';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const getDate = (date) => {
  if (!date) return 'Never';
  return moment(date).calendar();
};
const ProjectBackups = (props) => {
  const dataProvider = useDataProvider();

  const { classes: initialClasses, project } = props;
  const defaultdisableBackups =
    project && project._disable_backups ? true : false;
  const [disableBackups, setDisableBackups] = React.useState(
    defaultdisableBackups,
  );

  const setBackupStatus = async (disabled) => {
    try {
      const data = {
        _disable_backups: disabled,
      };
      console.log(data);
      const updateReq = await dataProvider.update('fw/projects', {
        id: project.id,
        data,
      });
      setDisableBackups(disabled);
      console.log(updateReq);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card className={initialClasses.infoCard}>
      <CardHeader
        title="Fieldwire Backups"
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={initialClasses.infoCardContent}>
        <TableContainer>
          <Table aria-label="Project Overview">
            <TableBody>
              <StyledTableRow>
                <TableCell component="th" scope="row">
                  Backup Status
                </TableCell>
                <TableCell>
                  <Select
                    labelId="backup-status"
                    id="backup-status"
                    value={disableBackups}
                    onChange={(e) => setBackupStatus(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={false}>Enabled</MenuItem>
                    <MenuItem value={true}>Disabled</MenuItem>
                  </Select>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">
                  Data Sync
                </TableCell>
                <TableCell>
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {getDate(project._last_sync)}
                    <Button>Run</Button>
                  </Box>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">
                  File-only Backup
                </TableCell>
                <TableCell>
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {getDate(project._last_file)}
                    <Button>Run</Button>
                  </Box>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">
                  Full Backup
                </TableCell>
                <TableCell>
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {getDate(project._last_backup)}

                    <Button>Run</Button>
                  </Box>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ProjectBackups;
