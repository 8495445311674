import { put, takeEvery, call } from 'redux-saga/effects';
import { showNotification, CREATE } from 'react-admin';
import dataProvider from '../dataProvider';

export default function* backupProjectSaga() {
  yield takeEvery('BACKUP_PROJECT', function* (action) {
    try {
      yield call(dataProvider, CREATE, 'job_processes/linked', {
        data: action.payload,
      });

      yield put(showNotification('Backup has been scheduled.'));
    } catch (error) {
      console.error(error);
      yield put(showNotification('Error creating project backup.'));
    }
  });
}
