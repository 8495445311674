import React from 'react';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Welcome from './Welcome';
import Counts from './Counts';
import JobProcessCard from './JobProcessCard';
import { connect } from 'react-redux'

const useStyles = makeStyles({
  grid: {
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
  },
});

const mapStateToProps = ({aadResponse}) => {
  console.log('aadResponse', aadResponse)
  return {
    aadResponse
  }
}

export default connect(mapStateToProps, null)((props) => {
  // eslint-disable-next-line
  const [summaryCurrent, setSummaryCurrent] = React.useState({
    projects: 1332,
    users: 2454,
    floorplans: 80323,
    sheets: 1345432,
  });
  
  const classes = useStyles();
  return (
    <div>
      <Title title="PDI FW Dashboard" />
      <Grid className={classes.grid}>
        <Welcome />
        <Counts summaryCurrent={summaryCurrent} />
      </Grid>
      <Grid className={classes.grid}>
        <JobProcessCard  />
      </Grid>
    </div>
  );
})
