import React from 'react';
import { Layout } from 'react-admin';

import Sidebar from './Sidebar';
import Appbar from './Appbar';

export default (props) => {
  const { children, ...otherProps } = props;
  return (
    <div style={{ height: '100%' }} className="pdi-layout-wrap">
      <Layout {...otherProps} sidebar={Sidebar} appBar={Appbar}>
        <div className="pdi-layout-content">{children}</div>
      </Layout>
    </div>
  );
};
