import React, { Fragment, useCallback } from 'react';
import { List } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { Route, useHistory } from 'react-router-dom';
import { JobProcessShow } from './Show';
import ReviewListDesktop from './ListInner';
import JobProcessFilter from './JobProcessFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  list: {
    width: '100%',
  },
}));

export const JobProcessList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/job_processes');
  }, [history]);
  return (
    <div className={classes.root}>
      <Route path="/job_processes/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Fragment>
              <List
                {...props}
                perPage={25}
                className={classes.list}
                sort={{ field: 'record_updated_at', order: 'DESC' }}
                filters={<JobProcessFilter />}
              >
                <ReviewListDesktop
                  selectedRow={isMatch && parseInt(match.params.id, 10)}
                />
              </List>
              {isMatch ? (
                <JobProcessShow
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : null}
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};
