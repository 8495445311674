import React from 'react';
import { Link } from 'react-admin';
import tableIcons from '../../components/TableIcons';
import MaterialTable from 'material-table';

export default props => {
  const { userId } = props;
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={[
          {
            title: 'Name',
            render: row => {
              return (
                <Link to={`/fw/projects/${row.project_id}/show`}>
                  {row.project.name}
                </Link>
              );
            },
            sorting: false,
            searchable: false,
          },
          {
            title: 'Role',
            field: 'role',
            sorting: false,
            searchable: false,
          },
        ]}
        title="Projects"
        options={{
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          emptyRowsWhenPaging: false,
          search: false,
          sorting: false,
          draggable: false,
        }}
        icons={tableIcons}
        data={query =>
          new Promise((resolve, reject) => {
            let url = `${process.env.REACT_APP_API_URL}/fw/users/${userId}/projects?`;
            url += 'limit=' + query.pageSize;
            url += '&page=' + (query.page + 1);
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    </div>
  );
};
