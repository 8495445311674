// authProvider.js
import { MsalAuthProvider, LoginType, UserAgentApplication } from 'react-aad-msal';
import { Logger, LogLevel } from "msal";
// Msal Configurations

const config= {
  auth: {
    authority: 'https://login.microsoftonline.com/1a8f76c8-eca8-4587-a402-13a97f1415cb',
    clientId: '450f3043-8f9f-46b2-9c40-5b56e7b056bf',
    redirectUri: "https://netlify.powerdesigninc.us",
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false
  },
  // Enable logging of MSAL events for easier troubleshooting.
      // This should be disabled in production builds.
      system: {
        logger: new Logger(
          (logLevel, message, containsPii) => {
            console.log("[MSAL]", message);
          },
          {
            level: LogLevel.Verbose,
            piiLoggingEnabled: false
          }
        )},
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};
// Authentication Parameters
const authenticationParameters = {
scopes: [
`450f3043-8f9f-46b2-9c40-5b56e7b056bf/.default`
]
}
const authenticationParametersGraph = {
scopes: [
'User.Read'
]
}
// Options
const options = {
loginType: LoginType.Redirect,
}
const authProvider = new MsalAuthProvider(config, authenticationParametersGraph, options)


export { authProvider};
