export const API_URL = 'http://pdivlnodejsdev:88';
export const FW_ROLE_FOLLOWER = 'follower';
export const FW_ROLE_MEMBER = 'member';
export const FW_ROLE_ADMIN = 'admin';
export const FW_ROLES = [FW_ROLE_FOLLOWER, FW_ROLE_MEMBER, FW_ROLE_ADMIN];
export const JOB_DEF = {
  AddUsersToProject: 'add_users_to_project',
  ArchiveProjectFromFw: 'archive_project_from_fw',
  BackupFwFilesToEgnyte: 'backup_fw_files_to_egnyte',
  GenerateExportFromFw: 'generate_export_from_fw',
  RestoreProjectToFw: 'restore_project_to_fw',
  SyncProjectListWithFw: 'sync_project_list_with_fw',
  SyncProjectWithFw: 'sync_project_with_fw',
  AddEntityToProjects: 'add_entity_to_projects',
  AddFormTemplateToProject: 'add_form_template_to_project',
  AddChecklistToProject: 'add_checklist_to_project',
  AddLocationToProject: 'add_location_to_project',
  AddTagToProject: 'add_tag_to_project',
  AddFolderToProject: 'add_folder_to_project',
  AddCategoryToProject: 'add_category_to_project',
  AddCustomStatusToProject: 'add_custom_status_to_project'
};
