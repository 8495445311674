import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const statusColor = {
  WAIT: '#fdd835',
  DONE: '#8bc34a',
  PROCESS: '#03a9f4',
  PAUSE: '#9bb6c3',
  ERROR: '#fb6a6a',
};
const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    padding: '6px 8px',
    flexGrow: 0,
    fontSize: '0.75rem',
    minWidth: 20,
    whiteSpace: 'nowrap',
    borderRadius: 2,
    letterSpacing: 0.5,
    lineHeight: 1,
    textTransform: 'uppercase',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.9)',
    backgroundColor: (props) => {
      return statusColor[props.status]
        ? statusColor[props.status]
        : statusColor['PAUSE'];
    },

    // backgroundColor: (props) =>
    //   statusColor[props.status]
    //     ? statusColor[props.status]
    //     : statusColor['PAUSE'],
  },
});

const JobProcessStatus = (props) => {
  const { status } = props;
  const classes = useStyles(props);

  return <div className={classes.root}>{status}</div>;
};

export default JobProcessStatus;
