import React from 'react';
import { useDataProvider, ArrayInput, SimpleFormIterator, TextInput, SimpleForm } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(TableRow);

const BasicEntities = (props) => {

  const { classes: initialClasses } = props;
  console.log('props', props)
  return (
    <Card>
      <CardHeader
        title= {`Add ${props.name}`}
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <SimpleForm
          save={props.onPush}
        >
          <ArrayInput label="" source={props.source}>
             <SimpleFormIterator>
               <TextInput label=''/>
             </SimpleFormIterator>
           </ArrayInput>
        </SimpleForm>
      </CardContent>
    </Card>
  )

}

export default BasicEntities;
