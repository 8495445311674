import React, { Fragment } from 'react';
import { Button, useUnselectAll, SimpleForm, TextInput, SelectInput, required, Create,FormDataConsumer } from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { useDispatch } from 'react-redux';
import { JOB_DEF } from '../constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InviteUsers from './SingleProject/Summary/InviteUsers';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useForm } from 'react-final-form';
import FormTemplates from './SingleProject/Summary/FormTemplates';
import ChecklistTemplates from './SingleProject/Summary/ChecklistTemplates';
import BasicEntities from './SingleProject/Summary/BasicEntities';


const ArchiveProjectFromFwButton = ({ selectedIds }) => {
  // const dispatch = useDispatch();
  const unselectAll = useUnselectAll();

  const archiveProjects = () => {
    // TODO: Add a confirmation before actually archiving projects
    // Disabled until then just to be safe
    unselectAll('fw/projects');
    // for (const projectId of selectedIds) {
    //   dispatch({
    //     type: 'CREATE_JOB_PROCESS',
    //     payload: {
    //       job_definition: { id: JOB_DEF.ArchiveProjectFromFw },
    //       data: {
    //         project: {
    //           id: projectId,
    //         },
    //       },
    //     },
    //   });
    // }
  };
  return (
    <Button label="Archive on FW" onClick={archiveProjects}>
      <ArchiveIcon />
    </Button>
  );
};

const AddEntityButton = ({ selectedIds }) => {
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();

  const useStyles = makeStyles({
    backgroundColor : 'white',
    infoCard: {
      marginBottom: 25
    },
    infoCardHeader: {
      borderBottom: '1px solid #e0e0e0',
    },
    infoCardContent: {
      padding: 0,
    },
    infoCardActions: {
      borderTop: '1px solid #e0e0e0',
      padding: 20,
    },
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: '50%',
      padding: '0 30px',
    },
  });

  const entities = [
  {
    value: 1,
    label: 'Folder',
  },
  {
    value: 2,
    label: 'Users',
  },
  {
    value: 3,
    label: 'Form Template',
  },
  {
    value: 4,
    label: 'Checklist Template'
  },
  {
    value: 5,
    label: 'Locations'
  },
  {
    value: 6,
    label: 'Entity Tags'
  },
  {
    value: 7,
    label: 'Category'
  },
  {
    value: 8,
    label: 'Custom Statuses'
  }
];

  const [state, setState] = React.useState({
    showEntities: false,
    entity: 1
  })

  const handleChange = event => {
     setState({ ...state, entity: event.target.value});
     console.log("state.entity", state.entity)
  };

  const handleFolderAdditions = (names) => {
    setState({...state, showEntities: false})
    console.log("handleFolderAddition", state.folder_name, selectedIds)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddFolderToProject },
          data: { project: { id: projectId }, folder_names: names },
        },
      });
    }
  }
  const handleCategoryAdditions = (names) => {
    setState({...state, showEntities: false})
    console.log("handleFolderAddition", state.category_name, selectedIds)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddCategoryToProject },
          data: { project: { id: projectId }, category_names: names },
        },
      });
    }
  }

  const handleUserInvite = (users) => {
    setState({...state, showEntities: false})
    console.log('handleUserInvite', users, selectedIds)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddUsersToProject },
          data: { project: { id: projectId }, users },
        },
      });
    }
  };

  const handleFormTemplateAddition = (template_id, is_modifying) => {
    setState({...state, showEntities: false})
    console.log("handleFormTemplateAddition", template_id, selectedIds)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddFormTemplateToProject },
          data: { project: { id: projectId}, form_template_id: template_id, is_modifying: is_modifying },
        },
      });
    }
  };

  const handleChecklistTemplateAddition = (template_id, is_modifying) => {
    setState({...state, showEntities: false})
    console.log("handleFormTemplateAddition", template_id, selectedIds)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddChecklistToProject },
          data: { project: { id: projectId}, checklist_id: template_id, is_modifying: is_modifying },
        },
      });
    }
  };

  const handleLocationAdditions = (locations) => {
    setState({...state, showEntities: false})
    console.log("handleLocationAdditions", locations)
    for (const projectId of selectedIds) {
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddLocationToProject },
          data: { project: { id: projectId}, location_ids: locations.data.entity.data.location_ids}
        }
      })
    }
  };

  const handleTagAdditions = (tags) => {
    setState({...state, showEntities: false})
    console.log("handleTagAdditions", tags)
    for (const projectId of selectedIds){
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddTagToProject },
          data: { project: { id: projectId}, tag_ids: tags.data.entity.data.tag_ids}
        }
      })
    }
  }

  const handleCustomStatusAdditions = () => {
    setState({...state, showEntities: false})
    for( const projectId of selectedIds){
      dispatch({
        type: 'CREATE_JOB_PROCESS',
        payload: {
          job_definition: { id: JOB_DEF.AddCustomStatusToProject },
          data: { project: { id: projectId } }
        }
      })
    }
  }

  return (
    <div>
      <Button label="Add Entity" onClick={() => {setState({ ...state, showEntities: true})}}>
        <AddIcon />
      </Button>
      <Dialog
        className={useStyles.root}
        scroll='body'
        open={state.showEntities}
        onClose={() => setState({ ...state, showEntities: false})}>
          <TextField
          id="outlined-select-currency"
          select
          label="Select"
          className={useStyles.textField}
          value={state.entity}
          onChange={handleChange}
          SelectProps={{
            MenuProps: {
              className: useStyles.menu,
            },
          }}
          helperText="Select your entity."
          margin="normal"
          variant="outlined"
        >
          {entities.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        { (state.entity === 1) ?
        <BasicEntities classes={useStyles} name='Folder Names' source='data.entity.data.folder_names' onPush={handleFolderAdditions} />
        : (state.entity === 2) ?
          <InviteUsers classes={useStyles} onInvite={handleUserInvite} />
        : (state.entity === 3) ?
        <FormTemplates classes={useStyles} onPush={handleFormTemplateAddition} />
        : (state.entity === 4) ?
        <ChecklistTemplates classes={useStyles} onPush={handleChecklistTemplateAddition} />
        : (state.entity === 5) ?
        <BasicEntities classes={useStyles} name='Locations' source='data.entity.data.location_ids' onPush={handleLocationAdditions} />
        : (state.entity === 6) ?
        <BasicEntities classes={useStyles} name='Entity Tags' source='data.entity.data.tag_ids' onPush={handleTagAdditions} />
        : (state.entity === 7) ?
        <BasicEntities classes={useStyles} name='Category Names' source='data.entity.data.category_names' onPush={handleCategoryAdditions} />
        : (state.entity === 8) ? null // custom statuses dont require any user input
        : null }
      </Dialog>
    </div>
  );
}

const BackupProjectsButton = ({ selectedIds }) => {
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();

  const backupProjects = () => {
    unselectAll('fw/projects');
    for (const projectId of selectedIds) {
      dispatch({
        type: 'BACKUP_PROJECT',
        payload: {
          jp: {
            job_definition: {
              id: JOB_DEF.SyncProjectWithFw,
            },
            data: {
              project: {
                id: projectId,
              },
            },
          },
          d: {
            job_definition: {
              id: JOB_DEF.BackupFwFilesToEgnyte,
            },
            data: {
              project: {
                id: projectId,
              },
            },
          },
        },
      });
    }
  };
  return (
    <Button label="Backup Projects" onClick={backupProjects}>
      <CloudDownloadIcon />
    </Button>
  );
};
const ProjectBulkAction = (props) => {
  return (
    <Fragment>
      <ArchiveProjectFromFwButton {...props} />
      <BackupProjectsButton {...props} />
      <AddEntityButton {...props} />
    </Fragment>
  );
};

export default ProjectBulkAction;
