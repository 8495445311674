import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import JobProcessDetails from '../../../components/JobProcessDetails';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import JobProcessStatus from '../../../components/JobProcessStatus';
import Timer from '../../../components/Timer';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  skeletonWrap: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 5,
  },
  skeleton: {
    marginBottom: 15,
  },
});

const RecentJobProcesses = (props) => {
  const { classes: initialClasses, onRefresh, jobProcesses } = props;
  const classes = useStyles();
  const { data, loading, error } = jobProcesses;
  const [jobProcess, setJobProcess] = React.useState();
  const [jobDetailsOpen, setJobDetailsOpen] = React.useState(false);
  const timerRef = React.useRef();
  const handleRefresh = () => {
    timerRef.current.setPauseTimer(true);
    onRefresh();
    timerRef.current.setPauseTimer(false);
    timerRef.current.setTimeLeft(30);
  };
  return (
    <Card className={initialClasses.infoCard}>
      <CardHeader
        title="Recent Job Processes"
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={initialClasses.infoCardContent}>
        {error ? <p>Error fetching Job Processs</p> : null}
        {loading ? (
          <div className={classes.skeletonWrap}>
            {[0, 1, 2, 3, 4].map((idx) => (
              <Skeleton
                variant="rect"
                with="100%"
                height={54}
                className={classes.skeleton}
                key={idx}
              />
            ))}
          </div>
        ) : (
          <TableContainer>
            <Table aria-label="recent job processes">
              <TableHead>
                <TableRow>
                  <TableCell>Definition</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((jobProcess) => (
                  <StyledTableRow key={jobProcess.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        setJobProcess(jobProcess);
                        setJobDetailsOpen(true);
                      }}
                    >
                      {jobProcess.job_definition.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <JobProcessStatus status={jobProcess.status} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(jobProcess.record_created_at).format('ll')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(jobProcess.record_updated_at).format('ll')}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <JobProcessDetails
          open={jobDetailsOpen}
          handleClose={() => setJobDetailsOpen(false)}
          jobProcess={jobProcess}
        />
      </CardContent>
      <CardActions className={initialClasses.infoCardActions}>
        <Button
          size="medium"
          startIcon={<RefreshIcon />}
          onClick={() => handleRefresh()}
          endIcon={
            <Timer
              ref={timerRef}
              seconds={30}
              onTimeout={() => handleRefresh()}
            />
          }
          disabled={loading}
        >
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
};

export default RecentJobProcesses;
