import { put, takeEvery, call } from 'redux-saga/effects';
import { showNotification, CREATE } from 'react-admin';
import dataProvider from '../dataProvider';
import { Alert, positions } from 'react-alert'

export default function* createJobProcessSaga() {
  yield takeEvery('CREATE_JOB_PROCESS', function* (action) {
    try {
      yield call(dataProvider, CREATE, 'job_processes', {
        data: action.payload,
      });
      alert('Job process created.', { position: positions.MIDDLE })
      yield put(showNotification('Job process created.'));
    } catch (error) {
      console.error(error);
      alert('Error creating job process.', { position: positions.MIDDLE })
      yield put(showNotification('Error creating Job Process.'));
    }
  });
}
