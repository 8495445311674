import React from 'react';
import { useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    '& button': {
      borderRadius: '50%',
    },
    '& .MuiFab-root': {
      backgroundColor: 'white',
      color: '#e91e63',
    },
  },
}));

const QuickActions = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={{
          variant: 'round',
        }}
      >
        <SpeedDialAction
          icon={<HistoryIcon />}
          tooltipTitle="New Job Process"
          tooltipOpen
          tooltipPlacement="right"
          onClick={() => redirect('/job_processes/create')}
          FabProps={{
            variant: 'round',
          }}
        />
      </SpeedDial>
    </div>
  );
};

export default QuickActions;
