import React from 'react';
import {
  Filter,
  SearchInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
} from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="first_name" alwaysOn />
    <BooleanInput label="Is Confirmed" source="is_confirmed||$eq" />
    <TextInput label="First Name" source="first_name" />
    <TextInput label="Last Name" source="last_name" />
    <TextInput label="Email" source="email" />
    <TextInput label="Job Title" source="job_title" />
    <TextInput label="Company" source="company" />
    <DateTimeInput label="Created before" source="created_at||$lte" />
    <DateTimeInput label="Created after" source="created_at||$gte" />
  </Filter>
);

export default UserFilter;
