import React from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import ProjectAvatar from '../components/ProjectAvatar';
import JobProcessStatus from '../components/JobProcessStatus';
import { getUrl } from '../utils';

const useStyles = makeStyles({
  root: {
    width: 900,
    maxWidth: '100%',
  },
  tableCell: {
    fontSize: 12,
  },
  divider: {
    marginTop: 25,
    marginBottom: 25,
  },
  field: {
    marginBottom: 15,
  },
});

const JobProcessDetails = (props) => {
  const { jobProcess, open, handleClose } = props;
  const project = jobProcess && jobProcess.project ? jobProcess.project : null;
  const classes = useStyles();

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  if (!jobProcess) {
    return null;
  }

  const title =
    jobProcess.job_definition && jobProcess.job_definition.name
      ? jobProcess.job_definition.name
      : 'Job Definition name';
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        {title} <JobProcessStatus status={jobProcess.status} />
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Grid container spacing={2}>
            <Grid container item xs={6} direction="column">
              <div className={classes.field}>
                <Typography variant="button" display="block" gutterBottom>
                  Project
                </Typography>
                {project ? (
                  <Link to={getUrl('projects', project.id)}>
                    <ProjectAvatar
                      id={project.id}
                      name={project.name}
                      address={project.address}
                      logoUrl={project.logo_url}
                    />
                  </Link>
                ) : null}
              </div>
              <div className={classes.field}>
                <Typography variant="button" display="block" gutterBottom>
                  Created at
                </Typography>
                <Typography variant="body">
                  {jobProcess.record_created_at}
                </Typography>
              </div>
            </Grid>
            <Grid container item xs={6} direction="column">
              <div className={classes.field}>
                <Typography variant="button" display="block" gutterBottom>
                  Job Definition
                </Typography>
                <Typography variant="body">
                  {jobProcess.job_definition.name}
                </Typography>
              </div>
              <div className={classes.field}>
                <Typography variant="button" display="block" gutterBottom>
                  Job Process Data
                </Typography>
                <Typography variant="body">
                  {JSON.stringify(jobProcess.data)}
                </Typography>
              </div>
              <div className={classes.field}>
                <Typography variant="button" display="block" gutterBottom>
                  Updated at
                </Typography>
                <Typography variant="body">
                  {jobProcess.record_updated_at}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Divider variant="middle" className={classes.divider} />
          <Typography variant="h6" component="h3" gutterBottom>
            Events
          </Typography>

          <TableContainer>
            <Table size="small" aria-label="job process events">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobProcess.events.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <code>{row.title}</code>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <code>{row.message}</code>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {moment(row.record_created_at).isValid() ? (
                        <code>{moment(row.record_created_at).format()}</code>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobProcessDetails;
