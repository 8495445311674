import React from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  CheckboxGroupInput,
  required,
  FormDataConsumer,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InviteUsers from '../projects/SingleProject/Summary/InviteUsers';
import FormTemplates from '../projects/SingleProject/Summary/FormTemplates';
import ChecklistTemplates from '../projects/SingleProject/Summary/ChecklistTemplates';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  createBody: {
    marginTop: theme.spacing(3),
  },
}));

const resources = [
  {
    name: 'Project Stats',
    id: 'account/project_stats',
  },
  {
    name: 'Users',
    id: 'account/users',
  },
  {
    name: 'Attachments',
    id: 'attachments',
  },
  {
    name: 'Bubbles',
    id: 'bubbles',
  },
  {
    name: 'Bubble Markups',
    id: 'bubble_markups',
  },
  {
    name: 'Floorplans',
    id: 'floorplans',
  },
  {
    name: 'Folders',
    id: 'folders',
  },
  {
    name: 'Locations',
    id: 'locations',
  },
  {
    name: 'Sheets',
    id: 'sheets',
  },
  {
    name: 'Tasks',
    id: 'tasks',
  },
  {
    name: 'Teams',
    id: 'teams',
  },
  {
    name: 'Entity Tags',
    id: 'entity_tags',
  },
  {
    name: 'Entity Taggings',
    id: 'entity_taggings',
  },
  {
    name: 'Form Utilization',
    id: 'forms'
  },
  {
    name: 'User Usage',
    id: 'users'
  }
];

export const JobProcessCreate = (props) => {
  const classes = useStyles();

  const [formTemplates, setFormTemplates] = React.useState([]);
  const [checklistTemplates, setChecklistTemplates] = React.useState([]);

  const form_template_wave_1_id = '4dbc209a-8184-490b-a408-d0d3ef92ea4d';
  const dataProvider = useDataProvider();

  let form_templates = [];
  const getFormTemplates = async() => {
    try{
      const formTemplates = await dataProvider.getList(
        `fw/projects/${form_template_wave_1_id}/form_templates`,
        {
          filter: 'project||$eq' + form_template_wave_1_id,
          sort: { field: 'record_updated_at', order: 'DESC' },
          pagination: { page: 1, perPage: 10 },
        })
        .then((data) => {
          const valid_data = data.data.filter((record)=> {
            return (record.deleted_at === null)
          })

          setFormTemplates(valid_data)
        })
    } catch(error){
      console.log("error getting form templates", error)
    }
  }
  let checklist_templates = [];
  const getChecklistTemplates = async() => {
    try{
      const checklistTemplates = await dataProvider.getList(
        `fw/projects/${form_template_wave_1_id}/template_checklists`,
        {
          filter: 'project||$eq' + form_template_wave_1_id,
          sort: { field: 'record_updated_at', order: 'DESC' },
          pagination: { page: 1, perPage: 10 },
        })
        .then((data) => {
          const valid_data = data.data.filter((record)=> {
            return (record.deleted_at === null)
          })

          setChecklistTemplates(valid_data)
        })
    } catch(error){
      console.log("error getting checklist templates", error)
    }
  }

  if(formTemplates.length === 0){
    getFormTemplates()
  }
  if(checklistTemplates.length === 0){
    getChecklistTemplates()
  }

  return (
    <Create
      {...props}
      component={(props) => {
        const { children, ...others } = props;
        return (
          <div {...others}>
            <Typography variant="overline" display="block">
              Job Processes
            </Typography>
            <Typography variant="h5" gutterBottom>
              New Job Process
            </Typography>
            <Paper elevation={1} className={classes.createBody}>
              {children}
            </Paper>
          </div>
        );
      }}
    >
      <SimpleForm redirect="list">
        <SelectInput
          label="Select Job Definition"
          source="job_definition.id"
          choices={[
            { id: 'generate_export_from_fw', name: 'Generate Export from FW' },
            {
              id: 'add_entity_to_projects',
              name: 'Add Entity to All Projects',
            }
          ]}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) => {
            switch (formData?.job_definition?.id) {
              case 'generate_export_from_fw':
                return (
                  <Typography>
                  <CheckboxGroupInput
                    label="Select Resources"
                    source="data.resources"
                    choices={resources}
                    validate={required()}
                    helperText="Select resources sparingly as this is a FW API request heavy JobProcess."
                  />
                  {formData?.data?.resources.includes('forms') ?
                    <Typography>
                      <TextField label="Form Utilization Parameters"/>
                      <DateInput label="Start Date (optional)" source="data.start_date" />
                      <DateInput label="End Date (optional)" source="data.end_date" />
                    </Typography>
                  :null}
                  </Typography>
                );
              case 'add_entity_to_projects':
                return (
                  <Typography>
                    <SelectInput
                      label="Select Entity Type "
                      source="data.entity.type"
                      choices={[
                        { id: 'folder', name: 'Folder' },
                        { id: 'user', name: 'User' },
                        { id: 'form_template', name: 'Form Template'},
                        { id: 'checklist_template', name: 'Checklist Template'},
                        { id: 'location', name: 'Location'},
                        { id: 'entity_tag', name: 'Entity Tag'},
                        { id: 'category', name: 'Category'},
                        { id: 'statuses', name: 'Custom Statuses'}
                      ]}
                      validate={required()}
                    />
                    {formData?.data?.entity?.type === 'folder' ? (
                      <ArrayInput label="" source="data.entity.data.folder_names">
                         <SimpleFormIterator>
                           <TextInput label=''/>
                         </SimpleFormIterator>
                       </ArrayInput>
                    ) : formData?.data?.entity?.type === 'user' ? (
                      <Typography>
                      <TextInput
                          label="User Email"
                          source="data.entity.data.email"
                          />
                          <SelectInput
                          label="User Role"
                          source="data.entity.data.role"
                          choices={[
                            { id: 'admin', name: 'Admin' },
                            { id: 'member', name: 'Member' },
                            { id: 'follower', name: 'Follower' },
                          ]}
                        />
                     </Typography>
                   ) : formData?.data?.entity?.type === 'form_template' ? (
                     <Typography>
                        <SelectInput
                           label="Form "
                           source="data.entity.data.form_template_id"
                           choices={formTemplates}
                        />
                        <SelectInput
                           label="Action "
                           source="data.entity.data.is_modifying"
                           choices={[
                             { id: false, name: 'Create'},
                             { id: true, name: 'Modify'}
                           ]}
                        />
                     </Typography>
                   ) : formData?.data?.entity?.type === 'checklist_template' ? (
                     <Typography>
                        <SelectInput
                           label="Checklist "
                           source="data.entity.data.checklist_id"
                           choices={checklistTemplates}
                        />
                        <SelectInput
                           label="Action "
                           source="data.entity.data.is_modifying"
                           choices={[
                             { id: false, name: 'Create'},
                             { id: true, name: 'Modify'}
                           ]}
                        />
                     </Typography>
                   ) : formData?.data?.entity?.type === 'location' ? (
                     <Typography>
                     <ArrayInput label="" source="data.entity.data.location_ids">
                        <SimpleFormIterator>
                          <TextInput label=''/>
                        </SimpleFormIterator>
                      </ArrayInput>
                     </Typography>
                   ) : formData?.data?.entity?.type === 'entity_tag' ? (
                     <Typography>
                     <ArrayInput source="data.entity.data.tag_ids">
                        <SimpleFormIterator>
                          <TextInput label=''/>
                        </SimpleFormIterator>
                      </ArrayInput>
                     </Typography>
                   ) : formData?.data?.entity?.type === 'category' ? (
                     <Typography>
                     <ArrayInput label="" source="data.entity.data.category_names">
                        <SimpleFormIterator>
                          <TextInput label=''/>
                        </SimpleFormIterator>
                      </ArrayInput>
                     </Typography>
                   ) : formData?.data?.entity?.type === 'statuses' ? null // statuses don't have any user input
                    : null}
                  </Typography>
                );
              default:
                return null;
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
