import React from 'react';
import { Show, TabbedShowLayout, Tab, useGetOne } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Summary from './Summary';
import Floorplans from './Floorplans';
import Attachments from './Attachments';
import Users from './Users';
import EgnyteFiles from './EgnyteFiles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tabs: {
    backgroundColor: '#eaeaec',
    boxShadow: 'none',
  },
  tab: {
    padding: 0,
  },
});
const PostTitle = ({ record }) => {
  return <span>{record ? record.name : ''}</span>;
};

export const SingleProject = (props) => {

  const project = useGetOne(props.resource, props.id).data;

  const classes = useStyles();
  return (
    <Show
      title={<PostTitle />}
      {...props}
      component={(props) => {
        const { children, ...others } = props;
        return (
          <div {...others}>
            <Typography variant="overline" display="block">
              Projects
            </Typography>
            <Typography variant="h5" gutterBottom>
              {project && project.name ? project.name : 'Project Name'}
            </Typography>
            <div>{children}</div>
          </div>
        );
      }}
    >
      <TabbedShowLayout
        className={`${classes.tabs} pdi-tabs-transparent`}
        component="div"
      >
        <Tab label="summary">
          <Summary {...props} project={project} />
        </Tab>
        <Tab label="users" path="users">
          <Users projectId={props.id} />
        </Tab>
        <Tab label="floorplans" path="floorplans">
          <Floorplans projectId={props.id} />
        </Tab>
        <Tab label="attachments" path="attachments">
          <Attachments projectId={props.id} />
        </Tab>
        <Tab label="Egnyte Files" path="egnyte-files">
          <EgnyteFiles projectId={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
