import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

const Timer = forwardRef(({ seconds, fontSize, onTimeout }, ref) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [pauseTimer, setPauseTimer] = useState(false);
  const [timeoutTriggered, setTimeoutTriggered] = useState(false);
  useImperativeHandle(ref, () => ({
    setTimeLeft,
    setPauseTimer,
  }));
  useEffect(() => {
    const triggerTimeout = () => {
      if (timeoutTriggered) return;
      setTimeoutTriggered(false);//setTimeoutTriggered(true);
      onTimeout();
    };
    if (pauseTimer) return;
    // exit early when we reach 0
    if (!timeLeft) {
      triggerTimeout();
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, timeoutTriggered, onTimeout, pauseTimer]);

  return <span style={{ fontSize }}>{timeLeft}</span>;
});

Timer.defaultProps = {
  fontSize: 12,
};
export default Timer;
