import React from 'react';
import { useDataProvider } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(TableRow);

const FormTemplates = (props) => {

  const [formTemplates, setFormTemplates] = React.useState(
    []
  );

  const { classes: initialClasses } = props;
  const dataProvider = useDataProvider();
  const formTemplatesFieldRef = React.useRef();
  const { templates } = props;
  const form_template_wave_1_id = '4dbc209a-8184-490b-a408-d0d3ef92ea4d';

  let form_templates = [];
  const getFormTemplates = async() => {
    try{
      const formTemplates = await dataProvider.getList(
        `fw/projects/${form_template_wave_1_id}/form_templates`,
        {
          filter: 'project||$eq' + form_template_wave_1_id,
          sort: { field: 'record_updated_at', order: 'DESC' },
          pagination: { page: 1, perPage: 10 },
        })
        .then((data) => {
          const valid_data = data.data.filter((record)=> {
            return (record.deleted_at === null)
          })
          
          setFormTemplates(valid_data)
        })
    } catch(error){
      console.log("error getting form templates", error)
    }
  }

  if(formTemplates.length === 0){
    getFormTemplates()
  }

  return (
    <Card className={initialClasses.infoCard}>
      <CardHeader
        title="Add Form Template"
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={initialClasses.infoCardContent}>
        <TableContainer >
          <TableBody fullWidth>
            {formTemplates.map((template) => (
              <StyledTableRow key={template.id} >

                  <TextField
                    id="standard-basic"
                    value={template.name}
                    multiline
                  />
                  <Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="th"
                    scope="row"
                    onClick={() => {
                      console.log("clicked", template.id);
                      props.onPush(template.id, false)
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="th"
                    scope="row"
                    onClick={() => {
                      console.log("clicked", template.id);
                      props.onPush(template.id, true)
                    }}
                  >
                    Modify
                  </Button>
                  </Typography>
              </StyledTableRow>
            ))}
          </TableBody>
        </TableContainer>
      </CardContent>
    </Card>
  )

}

export default FormTemplates;
