import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
// import { useDataProvider } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserProjects from './UserProjects';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  infoCardHeader: {
    borderBottom: '1px solid #e0e0e0',
  },
  infoCardContent: {
    padding: 0,
  },
  infoCardActions: {
    padding: 20,
  },
});
const PostTitle = ({ record }) => {
  return (
    <span>{record ? record.first_name + ' ' + record.last_name : ''}</span>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const UserInfo = (props) => {
  const classes = useStyles();
  const rows = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'email' },
    { label: 'Job Title', key: 'job_titlle' },
    { label: 'Company', key: 'company' },
    { label: 'Current Sign in At', key: 'current_sign_in_at' },
    { label: 'Email Notifications', key: 'email_notifications' },
    { label: 'Is Confirmed', key: 'is_confirmed', isBoolean: true },
    {
      label: 'Is Email Deliverable',
      key: 'is_email_deliverable',
      isBoolean: true,
    },
    { label: 'Created At', key: 'created_at', isDate: true },
    { label: 'Archived At', key: 'archived_at', isDate: true },
  ];
  const { record } = props;
  return (
    <Grid item xs={3} justifyContent="center">
      <Card>
        <CardHeader
          title="User Info"
          className={classes.infoCardHeader}
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent className={classes.infoCardContent}>
          <TableContainer>
            <Table aria-label="User Info">
              <TableBody>
                {rows.map((row) => {
                  const { label, key, isDate, isBoolean } = row;
                  let value = record && record[key] ? record[key] : null;
                  if (isDate && value) {
                    value = moment(value).format('ll');
                  }
                  if (isBoolean) {
                    value = value ? 'Yes' : 'No';
                  }
                  if (!value) {
                    value = '-';
                  }
                  return (
                    <StyledTableRow key={key}>
                      <TableCell component="th" scope="row">
                        {label}
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

const UserInner = (props) => {
  const { record, id } = props;
  return (
    <Grid container spacing={2}>
      <UserInfo record={record} />
      <Grid item xs={9} justifyContent="center">
        <UserProjects userId={id} />
      </Grid>
    </Grid>
  );
};

export default (props) => {
  // const dataProvider = useDataProvider();
  // const [projectsData, setProjectsData] = useState({
  //   projects: [],
  //   loading: true,
  //   error: null,
  // });
  // useEffect(() => {
  //   dataProvider
  //     .getList(`fw/users/${props.id}/projects`, {
  //       filter: {},
  //       sort: { field: 'project_id', order: 'ASC' },
  //       pagination: { page: 1, perPage: 50 },
  //     })
  //     .then(({ data }) => {
  //       setProjectsData({
  //         projects: data,
  //         loading: false,
  //         error: null,
  //       });
  //     })
  //     .catch((error) => {
  //       setProjectsData({
  //         projects: [],
  //         loading: false,
  //         error: error,
  //       });
  //     });
  // }, [dataProvider, props.id]);
  const user = useSelector((state) => {
    const data = state.admin.resources['fw/users'].data;
    if (!data || !data[props.id]) {
      return null;
    }
    return data[props.id];
  });
  return (
    <Show
      title={<PostTitle />}
      {...props}
      component={(props) => {
        const { children, ...others } = props;
        return (
          <div {...others}>
            <Typography variant="overline" display="block">
              Users
            </Typography>
            <Typography variant="h5" gutterBottom>
              {user && user.first_name
                ? user.first_name + ' ' + user.last_name
                : 'Full Name'}
            </Typography>
            <div>{children}</div>
          </div>
        );
      }}
    >
      <SimpleShowLayout>
        <UserInner id={props.id} />
      </SimpleShowLayout>
    </Show>
  );
};
