import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import QuickActions from './QuickActions';

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: 'white',
  },
});

export default (props) => {
  const classes = useSidebarStyles();
  return (
    <div style={{ backgroundColor: 'white', position: 'relative' }}>
      <Sidebar classes={classes} {...props} />
      <QuickActions />
    </div>
  );
};
