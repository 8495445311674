import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux'
import { store } from './redux/reducers'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';

/*******************************************************************************
  Redux imports
*******************************************************************************/

import {
  setUser
} from './redux/actions';

const mapStateToProps = () => {
  return {};
}

const mapDispatchToProps = {
  setUser
}



ReactDOM.render(
  // Using a function inside the component will give you control of what to show for each state
<AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
{
    ({login, logout, authenticationState, error, accountInfo}) => {
      switch (authenticationState) {
        case AuthenticationState.Authenticated:

          return (
            <p>
              <span>Welcome, {accountInfo.account.name}!</span>
              <button onClick={logout}>Logout</button>
            </p>
          );
        case AuthenticationState.Unauthenticated:
          return (
            <div>
              {error && <p><span>An error occurred during authentication, please try again!</span></p>}
              <p>
                <span>Hey stranger, you look new!</span>
                <button onClick={login}>Login</button>
              </p>
            </div>
          );
        case AuthenticationState.InProgress:
          return (<p>Authenticating...</p>);
      }
    }
  }
<App/>
</AzureAD>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
