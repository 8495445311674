import { createStore, combineReducers, applyMiddleware } from 'redux';
import { adminReducer, adminSaga, i18nReducer } from 'react-admin';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  backupProjectSaga,
  createJobProcessSaga,
  currentSummarySaga,
} from '../../sagas';
import dataProvider from '../../dataProvider';
import { authProvider } from '../../authProvider';

import {
  AAD_LOGIN_SUCCESS,
} from '../types';

const INIT_STATE  = {
  aadResponse  : {},
};

export const UserReducer  = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'AAD_LOGIN_SUCCESS':
    console.log("aad_login_success", action)
      return { ...state, aadResponse: action.payload };
    case 'AAD_LOGOUT_SUCCESS':
      return { ...state, aadResponse: null };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  aadResponse     : UserReducer,
  admin           : adminReducer
});

function* rootSaga(){
  yield all(
    [
      adminSaga(dataProvider, authProvider)
    ]
  )
}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(backupProjectSaga)
sagaMiddleware.run(createJobProcessSaga)
sagaMiddleware.run(currentSummarySaga)
sagaMiddleware.run(rootSaga)

export { store };
