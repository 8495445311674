import React, { useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import numbro from 'numbro';
import MaterialTable from 'material-table';

const EgnyteFiles = (props) => {
  const { projectId } = props;
  const dataProvider = useDataProvider();
  const [files, setFiles] = React.useState({
    data: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    async function getEgnyteFiles() {
      try {
        const { data } = await dataProvider.getList(
          `fw/projects/${projectId}/egnyte-files`,
          {
            filter: {},
            sort: { field: 'record_updated_at', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
          },
        );
        setFiles({
          data,
          loading: false,
          error: null,
        });
      } catch (error) {
        setFiles({
          data: [],
          loading: false,
          error,
        });
      }
    }
    getEgnyteFiles();
  }, [dataProvider, projectId]);
  // https://github.com/patrickjuchli/basic-ftp/blob/HEAD/src/FileInfo.ts
  const fileTypes = {
    0: 'Unknown',
    1: 'File',
    2: 'Directory',
    3: 'SymbolicLink',
  };
  return (
    <div>
      <MaterialTable
        title="Egnyte Files"
        columns={[
          { title: 'Name', field: 'name' },
          {
            title: 'Type',
            render: (row) =>
              row?.type && fileTypes[row.type]
                ? fileTypes[row.type]
                : fileTypes[0],
          },
          {
            title: 'Size',
            render: (row) =>
              row?.size && typeof row.size === 'number'
                ? numbro(row.size).format({
                    output: 'byte',
                    base: 'binary',
                    mantissa: 1,
                  })
                : '-',
          },
          { title: 'Modified At', field: 'modifiedAt', type: 'datetime' },
        ]}
        data={files.data}
        isLoading={files.loading}
        options={{
          search: false,
          sorting: false,
          toolbar: false,
          showTitle: false,
          draggable: false,
          paging: false,
        }}
      />
    </div>
  );
};

export default EgnyteFiles;
