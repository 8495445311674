import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import QuickActions from './QuickActions';
import { authProvider } from '../authProvider';

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: 'white',
  },
});

const Logout = () => (
  <p>
  <button onClick={() => authProvider.logout()}>Logout</button>
  </p>
);

const MyUserMenu = props => (
      <Logout />
);

export default (props) => {
  const classes = useSidebarStyles();
  return (
    <div style={{ backgroundColor: 'white', position: 'relative' }}>
      <AppBar classes={classes} {...props}
        userMenu={<MyUserMenu />}/>
      <QuickActions />
    </div>
  );
};
