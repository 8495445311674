import React from 'react';
import { Filter, SelectInput } from 'react-admin';

const JobProcessFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="status||$eq"
      choices={[
        { id: 'WAIT', name: 'Wait' },
        { id: 'READY', name: 'Ready' },
        { id: 'PROCESS', name: 'Process' },
        { id: 'DONE', name: 'Done' },
        { id: 'ERROR', name: 'Error' },
        { id: 'CANCEL', name: 'Cancel' },
      ]}
    />
  </Filter>
);

export default JobProcessFilter;
