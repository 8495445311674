import React from 'react';

import { Provider } from 'react-redux';
import { store } from './redux/reducers';

import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { createMuiTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import Layout from './components/Layout';
import Dashboard from './dashboard';
import { ProjectList } from './projects/List';
import { SingleProject } from './projects/SingleProject';
import { UserList } from './users/List';
import SingleUser from './users/SingleUser';
import { JobProcessList } from './job-processes/List';
import { JobProcessCreate } from './job-processes/Create';
import { currentSummary } from './reducers';
import {
  backupProjectSaga,
  createJobProcessSaga,
  currentSummarySaga,
} from './sagas';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import './App.css';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
});
const myTheme = createMuiTheme({
  palette: {
    primary: pink,
    secondary: grey,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: '#eaeaec',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  sidebar: {},
  appFrame: {
    flex: 1,
    flexDirection: 'column',
    overflowX: 'auto',
  },
});

const App = () => {
  return (
    <Provider store={store}>
    <Admin
      title="PDI FW Dashboard"
      dataProvider={dataProvider}
      dashboard={Dashboard}
      theme={myTheme}
      customReducers={{ currentSummary }}
      customSagas={[
        backupProjectSaga,
        createJobProcessSaga,
        currentSummarySaga,
      ]}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      <Resource
        name="fw/projects"
        list={ProjectList}
        icon={BusinessIcon}
        options={{ label: 'Projects' }}
        show={SingleProject}
      />
      <Resource
        name="fw/users"
        list={UserList}
        icon={PeopleIcon}
        options={{ label: 'Users' }}
        show={SingleUser}
      />
      <Resource
        name="job_processes"
        list={JobProcessList}
        create={JobProcessCreate}
        icon={HistoryIcon}
        options={{ label: 'Job Processes' }}
      />
    </Admin>
    </Provider>
  );
};
export default App;
