import React from 'react';
import MaterialTable from 'material-table';
import tableIcons from '../../components/TableIcons';
import LinkIcon from '@material-ui/icons/Link';

const Floorplans = (props) => {
  const { projectId } = props;
  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: 'Name',
            field: 'name',
            sorting: false,
            searchable: false,
          },
          {
            title: 'Description',
            field: 'description',
            sorting: false,
            searchable: false,
          },
          {
            title: 'Sheets',
            render: (row) =>
              Array.isArray(row.sheets) ? row.sheets.length : 0,
            type: 'numeric',
            sorting: false,
            searchable: false,
          },
        ]}
        title="Floorplans"
        options={{
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          actionsColumnIndex: -1,
          filtering:true,
        }}
        icons={tableIcons}
        actions={[
          {
            icon: LinkIcon,
            tooltip: 'Go to FieldWire',
            onClick: (event, row) =>
              window.open(
                `https://app.fieldwire.com/#!/projects/${projectId}/plans/${row.id}`,
              ),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = `${process.env.REACT_APP_API_URL}/fw/projects/${projectId}/floorplans?`;
            url += 'limit=' + query.pageSize;
            url += '&page=' + (query.page + 1);
            let qFilters = query.filters;
            if(qFilters.length>0){
              for(let i=0; i<qFilters.length;i++){
                let qfield= qFilters[i].column.field;
                let qval = qFilters[i].value;
                var furi = qfield+"||$cont||"+qval;
                console.log(furi );
                url += '&filter='+encodeURI(furi);
              }
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
        detailPanel={(row) => {
          return (
            <MaterialTable
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Version', field: 'version', type: 'numeric' },
                { title: 'Version Notes', field: 'version_notes' },
                { title: 'Version Description', field: 'version_description' },
              ]}
              data={row.sheets}
              options={{
                search: false,
                sorting: false,
                toolbar: false,
                showTitle: false,
                draggable: false,
                paging: false,
              }}
            />
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </div>
  );
};

export default Floorplans;
