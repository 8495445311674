import React from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { FW_ROLE_FOLLOWER, FW_ROLE_MEMBER, FW_ROLE_ADMIN } from '../constants';
import { capitalize } from '../utils';

const { forwardRef, useImperativeHandle } = React;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const InviteUsersField = forwardRef((props, ref) => {
  const classes = useStyles();
  const notify = useNotify();

  const emptyUser = {
    email: '',
    role: '',
  };
  const [users, setUsers] = React.useState([{ ...emptyUser }]);

  useImperativeHandle(ref, () => ({
    addUserRow: () => {
      setUsers([...users, { ...emptyUser }]);
    },
    handleSubmit: () => {
      let errMsg;
      // Validate users
      users.forEach((user, idx) => {
        if (!user.email || !user.role) {
          errMsg = `Email or Role not provided for user at index ${idx}`;
        }
      });
      if (errMsg) {
        notify('Error message', 'warning');
        return;
      }
      props.onInvite(users);
      setUsers([{ ...emptyUser }]);
    },
  }));
  const deleteUserRow = (rowIndex) => {
    const updatedUsers = [...users];
    updatedUsers.splice(rowIndex, 1);
    setUsers(updatedUsers);
  };
  const handleChange = (idx) => (event) => {
    const updatedUsers = [...users];
    const { name, value } = event.target;
    updatedUsers[idx][name] = value;
    setUsers(updatedUsers);
  };

  return (
    <div>
      {users.map(({ email, role }, idx) => (
        <Grid container spacing={0} key={idx}>
          <Grid container item spacing={1} xs={7}>
            <FormControl className={classes.margin} fullWidth>
              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={handleChange(idx)}
                required
              />
            </FormControl>
          </Grid>
          <Grid container item spacing={1} xs={4}>
            <FormControl
              className={classes.margin}
              fullWidth
              variant="outlined"
              required
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                native
                labelId="role-label"
                id="role"
                name="role"
                value={role}
                onChange={handleChange(idx)}
                label="Role"
                required
              >
                <option aria-label="None" value="" />
                <option value={FW_ROLE_ADMIN}>
                  {capitalize(FW_ROLE_ADMIN)}
                </option>
                <option value={FW_ROLE_MEMBER}>
                  {capitalize(FW_ROLE_MEMBER)}
                </option>
                <option value={FW_ROLE_FOLLOWER}>
                  {capitalize(FW_ROLE_FOLLOWER)}
                </option>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            xs={1}
            alignItems="center"
            justify="center"
          >
            <IconButton
              aria-label="delete"
              size="small"
              edge="end"
              disabled={idx === 0}
              onClick={() => deleteUserRow(idx)}
              className={classes.margin}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
});

export default InviteUsersField;
