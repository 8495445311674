import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const UserAvatar = props => {
  const classes = useStyles();
  const { photoUrl, size, firstName, lastName } = props;
  return (
    <div className={classes.root}>
      <Avatar
        src={photoUrl}
        style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
        className={classes.avatar}
      />
      {firstName} {lastName}
    </div>
  );
};

UserAvatar.defaultProps = {
  size: 25,
};

export default UserAvatar;
