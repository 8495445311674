import React from 'react';
import { Datagrid, DateField, TextField, Link } from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import ProjectAvatar from '../components/ProjectAvatar';
import JobProcessStatus from '../components/JobProcessStatus';

const ProjectField = ({ record }) => {
  if (!record.project) {
    return null;
  }
  return (
    <Link to={`/fw/projects/${record.project.id}/show`}>
      <ProjectAvatar
        id={record.project.id}
        name={record.project.name}
        address={record.project.address}
        logoUrl={record.project.logo_url}
      />
    </Link>
  );
};

const JobStatusField = ({ record }) =>
  record.status ? <JobProcessStatus status={record.status} /> : null;

const rowStyle = (selectedRow, theme) => (record, index, defaultStyle = {}) => {
  let style = defaultStyle;
  if (selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.status === 'DONE')
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'WAIT')
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'ERROR')
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return style;
};

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const ReviewListDesktop = ({ selectedRow, ...props }) => {
  const classes = useListStyles();
  const theme = useTheme();
  return (
    <Datagrid
      rowClick="edit"
      rowStyle={rowStyle(selectedRow, theme)}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <TextField source="job_definition.name" label="Process" />
      <ProjectField label="Project" />
      <JobStatusField label="Status" />
      <DateField source="record_created_at" showTime />
      <DateField source="record_updated_at" showTime />
    </Datagrid>
  );
};

export default ReviewListDesktop;
