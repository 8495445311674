import React from 'react';
import { useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import SyncIcon from '@material-ui/icons/Sync';
import moment from 'moment';
import ProjectBackups from './Backups';
import RecentJobProcesses from './RecentJobProcesses';
import OtherActions from './OtherActions';
import InviteUsers from './InviteUsers';
import FormTemplates from './FormTemplates';
import ChecklistTemplates from './ChecklistTemplates';
import BasicEntities from './BasicEntities';
import { JOB_DEF } from '../../../constants';
const useStyles = makeStyles({
  infoCard: {
    marginBottom: 25,
  },
  infoCardHeader: {
    borderBottom: '1px solid #e0e0e0',
  },
  infoCardContent: {
    padding: 0,
  },
  infoCardActions: {
    borderTop: '1px solid #e0e0e0',
    padding: 20,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default (props) => {
  const { project } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const overviewRows = [
    { label: 'Name', key: 'name' },
    { label: 'Code', key: 'code' },
    { label: 'Address', key: 'address' },
    { label: 'Created At', key: 'created_at', isDate: true },
    { label: 'Archived At', key: 'archived_at', isDate: true },
    { label: 'Deleted At', key: 'deleted_at', isDate: true },
  ];
  const usageRows = [
    { label: 'Users', key: 'user_count', relUrl: 'people' },
    { label: 'Sheets', key: 'sheet_count', relUrl: 'plans' },
    {
      label: 'Priority 1 Tasks',
      key: 'priority_1_task_count',
      relUrl: 'tasks',
    },
    {
      label: 'Priority 2 Tasks',
      key: 'priority_2_task_count',
      relUrl: 'tasks',
    },
    {
      label: 'Priority 3 Tasks',
      key: 'priority_3_task_count',
      relUrl: 'tasks',
    },
  ];
  const fwUrl = `https://app.fieldwire.com/#!/projects/${props.id}`;

  const dataProvider = useDataProvider();
  const syncFwData = () =>
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: {
        job_definition: { id: JOB_DEF.SyncProjectWithFw },
        data: { project: { id: props.id } },
      },
    });
  const handleUserInvite = (users) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: {
        job_definition: { id: JOB_DEF.AddUsersToProject },
        data: { project: { id: props.id }, users },
      },
    });
  };
  const handleFormTemplateAddition = (template_id, is_modifying) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: {
        job_definition: { id: JOB_DEF.AddFormTemplateToProject },
        data: { project: { id: project.id}, form_template_id: template_id, is_modifying: is_modifying },
      },
    });
  }
  const handleChecklistTemplateAddition = (template_id, is_modifying) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: {
        job_definition: { id: JOB_DEF.AddChecklistToProject },
        data: { project: { id: project.id}, checklist_id: template_id, is_modifying: is_modifying },
      },
    });
  }
  const handleLocationAdditions = (locations) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload:{
        job_definition: { id: JOB_DEF.AddLocationToProject },
        data: { project: { id: project.id}, location_ids: locations.data.entity.data.location_ids}
      }
    })
  }
  const handleTagAdditions = (tags) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload:{
        job_definition: { id: JOB_DEF.AddTagToProject },
        data: { project: { id: project.id}, tag_ids: tags.data.entity.data.tag_ids}
      }
    })
  }
  const handleCategoryAdditions = (categories) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload:{
        job_definition: { id: JOB_DEF.AddCategoryToProject },
        data: { project: { id: project.id}, category_names: categories.data.entity.data.category_names}
      }
    })
  }
  const handleFolderAdditions = (folders) => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload:{
        job_definition: { id: JOB_DEF.AddFoldersToProject },
        data: { project: { id: project.id}, folder_names: folders.data.entity.data.folder_names}
      }
    })
  }
  const handleCustomStatusAdditions = () => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: {
        job_definition: { id: JOB_DEF.AddCustomStatusToProject },
        data: { project: { id: project.id } }
      }
    })
  }

  const [jobProcesses, setJobProcesses] = React.useState({
    data: [],
    loading: true,
    error: null,
  });

  const refreshJobProcesses = React.useCallback(() => {

    setJobProcesses({
      data: [],
      loading: true,
      error: null,
    });
    return dataProvider
      .getList('job_processes', {
        filter: 'project.id||$eq' + project.id,
        sort: { field: 'record_updated_at', order: 'DESC' },
        pagination: { page: 1, perPage: 10 },
      })
      .then(({ data }) => {
        setJobProcesses({
          data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        setJobProcesses({
          data: [],
          loading: false,
          error,
        });
        console.error(error, 'errpr');
      });
  }, [dataProvider, project.id]);
  React.useEffect(() => {
    refreshJobProcesses();
  }, [refreshJobProcesses]);

  return (
    <div {...props}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card className={classes.infoCard}>
            <CardHeader
              title="Overview"
              className={classes.infoCardHeader}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent className={classes.infoCardContent}>
              <TableContainer>
                <Table aria-label="Project Overview">
                  <TableBody>
                    {overviewRows.map((row) => {
                      const { label, key, isDate } = row;
                      let value = project && project[key] ? project[key] : null;
                      if (isDate && value) {
                        value = moment(value).format('ll');
                      }
                      if (!value) {
                        value = '-';
                      }
                      return (
                        <StyledTableRow key={key}>
                          <TableCell component="th" scope="row">
                            {label}
                          </TableCell>
                          <TableCell>{value}</TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions className={classes.infoCardActions}>
              <Button
                size="medium"
                startIcon={<EditIcon />}
                href={`${fwUrl}/settings`}
                target="_blank"
              >
                Edit on FW
              </Button>
              <Button
                onClick={syncFwData}
                size="medium"
                startIcon={<SyncIcon />}
                disabled={project.deleted_at ? true : false}
              >
                Sync FW Data
              </Button>
            </CardActions>
          </Card>
          <ProjectBackups classes={classes} project={project} />
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.infoCard}>
            <CardHeader
              title="Usage"
              className={classes.infoCardHeader}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent className={classes.infoCardContent}>
              <TableContainer>
                <Table aria-label="Project Usage">
                  <TableBody>
                    {usageRows.map((row) => {
                      const { label, key, relUrl } = row;
                      let value = project && project[key] ? project[key] : null;
                      if (!value) {
                        value = '-';
                      }
                      return (
                        <StyledTableRow key={key}>
                          <TableCell component="th" scope="row">
                            {label}
                          </TableCell>
                          <TableCell>
                            {value}{' '}
                            {relUrl ? (
                              <IconButton
                                aria-label="view on FW"
                                size="small"
                                href={`${fwUrl}/${relUrl}`}
                                target="_blank"
                              >
                                <LaunchIcon fontSize="inherit" />
                              </IconButton>
                            ) : null}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <OtherActions classes={classes} project={project} />
        </Grid>

        <Grid item xs={5}>
          <RecentJobProcesses
            classes={classes}
            jobProcesses={jobProcesses}
            onRefresh={refreshJobProcesses}
          />
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.infoCard}>
            <CardContent>
              <InviteUsers classes={classes} onInvite={handleUserInvite} />
            </CardContent>
          </Card>
          <Card className={classes.infoCard}>
            <CardContent>
              <BasicEntities classes={classes}
                name='Entity Tags'
                source='data.entity.data.tag_ids'
                onPush={handleTagAdditions} />
            </CardContent>
          </Card>
          <Card className={classes.infoCard}>
            <CardContent>
              <BasicEntities classes={classes}
                name='Locations'
                source='data.entity.data.location_ids'
                onPush={handleLocationAdditions} />
            </CardContent>
          </Card>
          <Card className={classes.infoCard}>
            <CardContent>
              <BasicEntities classes={classes}
                name='Categories'
                source='data.entity.data.category_names'
                onPush={handleCategoryAdditions} />
            </CardContent>
          </Card>
          <Card className={classes.infoCard}>
            <CardContent>
              <BasicEntities classes={classes}
                name='Folders'
                source='data.entity.data.folder_names'
                onPush={handleFolderAdditions} />
            </CardContent>
          </Card>
          <Card className={classes.infoCard}>
            <CardContent>
              <Button
                onClick={handleCustomStatusAdditions}
                size="medium"
                startIcon={<SyncIcon />}
              >
                Sync Custom Statuses
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.infoCard}>
            <CardContent>
              <FormTemplates classes={classes} onPush={handleFormTemplateAddition} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.infoCard}>
            <CardContent>
              <ChecklistTemplates classes={classes} onPush={handleChecklistTemplateAddition} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
