import React, {Component} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'

const useStyles = theme => ({
  rootGrid: {
    marginBottom: 25,
  },
  welcomeCol: {
    height: '100%',
  },
});

const mapStateToProps = ({aadResponse}) => {
  return {
    aadResponse
  }
}

class Welcome extends Component{

  constructor(props){
    super(props);

    this.state = {}
  }

  render(){
    const { classes } = this.props;
    console.log(this.props.aadResponse,
      this.props.aadResponse.aadResponse?.account?.name, this.props.aadResponse.aadResponse?.account?.name)
    return (
      <div>
        <Grid container spacing={2} className={classes.rootGrid}>
          <Grid item xs={6} justifyContent="center">
            <Grid
              container
              direction="column"
              justify="center"
              className={classes.welcomeCol}
            >
              <Typography variant="button" display="block" gutterBottom>
                Home
              </Typography>
              <Typography
                variant="h3"
                component="h2"
                display="block"
                gutterBottom
              >
                Welcome back, {this.props.aadResponse.aadResponse?.account?.name}.
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                Here’s what’s happening with your projects today.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              justifyContent="center"
              justify="center"
              className={classes.welcomeCol}
            >
              <img
                src="/welcome.jpg"
                style={{ maxWidth: '100%' }}
                alt="Welcome"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(Welcome))
