import React from 'react';
import {
  Filter,
  SearchInput,
  TextInput,
  DateTimeInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

const ProjectFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name||$contL" alwaysOn />
    <TextInput label="Address" source="address" />
    <TextInput label="Code" source="code" />
    <BooleanInput
      label="Backups Disabled"
      source="_disable_backups||$eq"
      defaultValue={false}
    />
    <DateTimeInput label="Created before" source="created_at||$lte" />
    <DateTimeInput label="Created after" source="created_at||$gte" />
    <QuickFilter
      source="archived_at||$isnull"
      label="Not Archived"
      defaultValue={true}
    />
    <QuickFilter
      source="archived_at||$notnull"
      label="Is Archived"
      defaultValue={true}
    />
    <DateTimeInput label="Archived before" source="archived_at||$lte" />
    <DateTimeInput label="Archived after" source="archived_at||$gte" />
    <QuickFilter
      source="deleted_at||$isnull"
      label="Not Deleted"
      defaultValue={true}
    />
    <QuickFilter
      source="deleted_at||$notnull"
      label="Is Deleted"
      defaultValue={true}
    />
    <DateTimeInput label="Deleted before" source="deleted_at||$lte" />
    <DateTimeInput label="Deleted after" source="deleted_at||$gte" />
    <NumberInput label="Sheets more than" source="sheet_count||$gte" />
    <NumberInput label="Sheets less than" source="sheet_count||$lte" />
    <NumberInput label="Users more than" source="user_count||$gte" />
    <NumberInput label="Users less than" source="user_count||$lte" />
  </Filter>
);

export default ProjectFilter;
