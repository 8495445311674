import React, { cloneElement } from 'react';
import {
  TopToolbar,
  ExportButton,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import IconEvent from '@material-ui/icons/Event';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { JOB_DEF } from '../constants';

const ProjectListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [syncConfirmOpen, setSyncConfirmOpen] = React.useState(false);
  const handleSyncConfirm = () => {
    dispatch({
      type: 'CREATE_JOB_PROCESS',
      payload: { job_definition: { id: JOB_DEF.SyncProjectListWithFw } },
    });
    setSyncConfirmOpen(false);
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
      <div>
        <Button onClick={() => setSyncConfirmOpen(true)} label="FW Sync">
          <FindReplaceIcon />
        </Button>
        <Dialog
          open={syncConfirmOpen}
          onClose={() => setSyncConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Sync Project list with FW?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sync Project list with FW with basic project stats?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={() => setSyncConfirmOpen(false)}>
              Cancel
            </MuiButton>
            <MuiButton
              onClick={() => handleSyncConfirm()}
              color="primary"
              autoFocus
            >
              Confirm
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
    </TopToolbar>
  );
};

ProjectListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default ProjectListActions;
