import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import InviteUsersField from '../../../components/InviteUsersField';

const InviteUsers = (props) => {
  const { classes: initialClasses } = props;

  const inviteUsersFieldRef = React.useRef();

  return (
    <Card>
      <CardHeader
        title="Invite Users"
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <InviteUsersField ref={inviteUsersFieldRef} onInvite={props.onInvite} />
      </CardContent>
      <CardActions className={initialClasses.infoCardActions}>
        <Grid container spacing={1}>
          <Grid container item xs={6}>
            <Button
              startIcon={<AddIcon />}
              onClick={() => inviteUsersFieldRef.current.addUserRow()}
            >
              Add row
            </Button>
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            {props.onInvite ? <Button
              variant="contained"
              color="secondary"
              startIcon={<PersonAddIcon />}
              onClick={() => inviteUsersFieldRef.current.handleSubmit()}
            >
              Invite
            </Button> : null}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default InviteUsers;
