import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-admin';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { getUrl } from '../utils';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textBox: {
    width: 400,
  },
  name: {
    lineHeight: 1.4,
    // fontWeight: 500,
  },
  address: {
    lineHeight: 1.4,
  },
}));

const ProjectAvatar = (props) => {
  const classes = useStyles();
  const { id, name, address, logoUrl, size } = props;
  return (
    <Link className={classes.root} to={getUrl('projects', id)}>
      <Avatar
        src={logoUrl}
        style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
        className={classes.avatar}
        variant="square"
      />
      <Box component="div" className={classes.textBox} title={name}>
        <Typography variant="subtitle1" className={classes.name} noWrap>
          {name}
        </Typography>
        <Typography
          variant="overline"
          className={classes.address}
          color="textSecondary"
          noWrap
        >
          {address}
        </Typography>
      </Box>
    </Link>
  );
};

ProjectAvatar.defaultProps = {
  size: 40,
};

export default ProjectAvatar;
