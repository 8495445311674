import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  BooleanField,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import UserAvatar from '../components/UserAvatar';
import UserFilter from './UserFilter';
export const PostIcon = BookIcon;

const FullNameField = (props) => {
  return (
    <UserAvatar
      photoUrl={props.record.photo_url}
      firstName={props.record.first_name}
      lastName={props.record.last_name}
    />
  );
};

export const UserList = (props) => {
  return (
    <List {...props} perPage={25}
    filters={<UserFilter />}
    >
      <Datagrid rowClick="show">
        <FullNameField label="Name" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="company" />
        <TextField source="job_title" />
        <DateField source="created_at" />
        <DateField source="current_sign_in_at" />
        <BooleanField source="is_confirmed" />
      </Datagrid>
    </List>
  );
};
