import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import numbro from 'numbro';

const useStyles = makeStyles({
  rootGrid: {
    marginBottom: 25,
  },
  countCol: {
    height: '100%',
    textAlign: 'center',
  },
});

export default props => {
  const { projects, users, floorplans, sheets } = props.summaryCurrent;
  const classes = useStyles();
  return (
    <div style={{ margin: '0 10px' }}>
      <Grid
        container
        spacing={3}
        component={Paper}
        className={classes.rootGrid}
      >
        <Grid item xs direction="column" className={classes.countCol}>
          <Typography variant="h4" component="h3" display="block">
            {numbro(projects).format({
              thousandSeparated: true,
            })}
          </Typography>
          <Typography variant="button" display="block">
            Users
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs direction="column" className={classes.countCol}>
          <Typography variant="h4" component="h3" display="block">
            {numbro(users).format({
              thousandSeparated: true,
            })}
          </Typography>
          <Typography variant="button" display="block">
            Projects
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs direction="column" className={classes.countCol}>
          <Typography variant="h4" component="h3" display="block">
            {numbro(floorplans).format({
              average: true,
            })}
          </Typography>
          <Typography variant="button" display="block">
            Floorplans
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs direction="column" className={classes.countCol}>
          <Typography variant="h4" component="h3" display="block">
            {numbro(sheets).format({
              average: true,
              mantissa: 2,
            })}
          </Typography>
          <Typography variant="button" display="block">
            Sheets
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
