const resourceBase = {
  projects: '/fw/projects',
  users: '/fw/users',
  job_processes: '/job_processes',
};

const resourceSuffix = {
  projects: 'show',
  users: 'show',
};

export const getUrl = (resource, id) => {
  const validResources = Object.keys(resourceBase);
  if (!validResources.includes(resource)) {
    throw new Error(
      `${resource} provided not in valid resources: ${validResources.join(
        ', ',
      )}`,
    );
  }
  const baseUrl = resourceBase[resource];
  const urlArr = [baseUrl];
  if (id) {
    urlArr.push(id);
  }
  if (resourceSuffix[resource]) {
    urlArr.push(resourceSuffix[resource]);
  }
  const urlString = urlArr.join('/');
  return urlString;
};
