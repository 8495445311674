import React from 'react';
import { Link } from 'react-admin';
import tableIcons from '../../components/TableIcons';
import UserAvatar from '../../components/UserAvatar';
import MuiLink from '@material-ui/core/Link';
import MaterialTable from 'material-table';

export default (props) => {
  const { projectId } = props;
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={[
          {
            title: 'Name',
            field: 'user.first_name',
            render: (row) => {
              return (
                <Link to={`/fw/users/${row.user_id}/show`}>
                  <UserAvatar
                    photoUrl={row.user.photo_url}
                    firstName={row.user.first_name}
                    lastName={row.user.last_name}
                  />
                </Link>
              );
            },
            sorting: false,
            searchable: false,
          },
          {
            title: 'Email',
            field: 'user.email',
            render: (row) =>
              row.user && row.user.email ? (
                <MuiLink
                  href={`mailto:${row.user.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.user.email}
                </MuiLink>
              ) : (
                ''
              ),
            sorting: false,
            searchable: false,
          },
          {
            title: 'Phone',
            field: 'user.phone',
            render: (row) =>
              row.user && row.user.phone ? (
                <MuiLink
                  href={`tel:${row.user.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.user.phone}
                </MuiLink>
              ) : (
                ''
              ),
            sorting: false,
            searchable: false,
          },
          {
            title: 'Job Title',
            field: 'user.job_title',
            sorting: false,
            searchable: false,
          },
          {
            title: 'Project Role',
            field: 'role',
            sorting: false,
            searchable: false,
          },
        ]}
        // data={users}
        title="Users"
        options={{ pageSize: 20, pageSizeOptions: [20, 50, 100],            
          //filtering:true, 
        }}
        icons={tableIcons}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = `${process.env.REACT_APP_API_URL}/fw/projects/${projectId}/users?`;
            url += 'limit=' + query.pageSize;
            url += '&page=' + (query.page + 1);
            // let qFilters = query.filters;
            // if(qFilters.length>0){
            //   for(let i=0; i<qFilters.length;i++){
            //     let qfield= qFilters[i].column.field;
            //     let qval = qFilters[i].value;
            //     var furi = qfield+"||$cont||"+qval;
            //     console.log(furi );
            //     url += '&filter='+encodeURI(furi);
            //   }
            // }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    </div>
  );
  // return (
  //   <TableContainer>
  //     <Table className={classes.table} aria-label="Project Users">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>Name</TableCell>
  //           <TableCell>Email</TableCell>
  //           <TableCell>Phone</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {!loading && Array.isArray(users)
  //           ? users.map(row => (
  //               <TableRow key={row.id}>
  //                 <TableCell component="th" scope="row">
  //                   <a href={`/#/fw/users/${row?.user?.id}/show`}>
  //                     {row?.user?.first_name} {row?.user?.last_name}
  //                   </a>
  //                 </TableCell>
  //                 <TableCell>{row?.user?.email}</TableCell>
  //                 <TableCell>{row?.user?.phone}</TableCell>
  //               </TableRow>
  //             ))
  //           : null}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
};
