import React from 'react';
import { useShowController } from 'react-admin';
import JobProcessDetails from '../components/JobProcessDetails';

export const JobProcessShow = (props) => {
  const { onCancel } = props;
  const { record } = useShowController(props);
  if (!record) return null;
  return <JobProcessDetails open handleClose={onCancel} jobProcess={record} />;
};
