import React from 'react';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import JobProcessView from './JobProcessView';
import moment from 'moment';

const useStyles = makeStyles({
  infoCard: {
    marginBottom: 25,
  },
  infoCardHeader: {
    borderBottom: '1px solid #e0e0e0',
  },
  infoCardContent: {
    padding: 0,
  },
  infoCardActions: {
    borderTop: '1px solid #e0e0e0',
    padding: 20,
  },
});

export default (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [jobProcesses, setJobProcesses] = React.useState({
    data: [],
    loading: true,
    error: null,
  });

  const refreshJobProcesses = React.useCallback(() => {
    setJobProcesses({
      data: [],
      loading: true,
      error: null,
    });
    const process_to = moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'); // DateTime.UtcNow.ToString("O");//Date.now().toString("yyyy-MM-dd'T'HH:mm:ss.fffffff'Z'");//new Date().ToString("yyyy-MM-dd'T'HH:mm:ss.fffffff'Z'");
    const process_from = moment()
      .add(-1, 'hours')
      .format('YYYY-MM-DDTHH:mm:ssZ');
    console.log(process_from + ' , ' + process_to);
    return dataProvider
      .getList('job_processes', {
        filter: 'process_at||$between' + '' + process_from + ',' + process_to + '',
        sort: { field: 'record_updated_at', order: 'DESC' },
        pagination: { page: 1, perPage: 4 },
      })
      .then(({ data }) => {
        setJobProcesses({
          data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        setJobProcesses({
          data: [],
          loading: false,
          error,
        });
        console.error(error, 'error');
      });
  }, [dataProvider]);
  React.useEffect(() => {
    refreshJobProcesses();
  }, [refreshJobProcesses]);

  return (
    <div {...props}>
      <Grid container spacing={2}>
        <Grid item xs={6} justifyContent="center">
          <JobProcessView
            classes={classes}
            jobProcesses={jobProcesses}
            onRefresh={refreshJobProcesses}
          />
        </Grid>
      </Grid>
    </div>
  );
};
