import { fetchUtils } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Access-Control-Expose-Headers', 'Content-Range');

  return fetchUtils.fetchJson(url, options);
};

export default crudProvider(process.env.REACT_APP_API_URL, httpClient);
