import React from 'react';
import { useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import RestoreIcon from '@material-ui/icons/Restore';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { JOB_DEF } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const OtherActions = (props) => {
  const { classes: initialClasses, project } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Card className={initialClasses.infoCard}>
      <CardHeader
        title="Other Actions"
        className={initialClasses.infoCardHeader}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={initialClasses.infoCardContent}>
        <List className={classes.root}>
          <ListItem
            alignItems="flex-start"
            button
            disabled={project.deleted_at ? true : false}
            onClick={() =>
              dispatch({
                type: 'BACKUP_PROJECT',
                payload: {
                  jp: {
                    job_definition: {
                      id: JOB_DEF.SyncProjectWithFw,
                    },
                    data: {
                      project: {
                        id: project.id,
                      },
                      forceFullBackup: true
                    },
                  },
                  d: {
                    job_definition: {
                      id: JOB_DEF.BackupFwFilesToEgnyte,
                    },
                    data: {
                      project: {
                        id: project.id,
                      },
                      forceFullBackup: true
                    },
                  },
                },
              })
            }
          >
            <ListItemAvatar>
              <Avatar>
                <CloudDownloadIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Full Project Backup"
              secondary={
                <Typography variant="body2" color="textSecondary">
                  Backup project data and files.
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            button
            disabled={project.deleted_at ? true : false}
            onClick={() =>
              dispatch({
                type: 'CREATE_JOB_PROCESS',
                payload: {
                  job_definition: { id: JOB_DEF.BackupFwFilesToEgnyte },
                  data: { project: { id: project.id } },
                },
              })
            }
          >
            <ListItemAvatar>
              <Avatar>
                <CloudDownloadIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Backup FW Files to Egnyte"
              secondary={
                <Typography variant="body2" color="textSecondary">
                  Download project files from FW and upload to Egnyte.
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            alignItems="flex-start"
            button
            disabled={
              !project._last_sync || !project._last_file
                ? true
                : false
            }
            onClick={() =>
              dispatch({
                type: 'CREATE_JOB_PROCESS',
                payload: {
                  job_definition: { id: JOB_DEF.RestoreProjectToFw },
                  data: { project: { id: project.id } },
                },
              })
            }
          >
            <ListItemAvatar>
              <Avatar>
                <RestoreIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Restore Project"
              secondary={
                <Typography variant="body2" color="textSecondary">
                  Restore a project on FW with latest backup.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default OtherActions;
