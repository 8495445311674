import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  Link,
  List,
  Datagrid,
  DateField,
  NumberField,
  Pagination,
} from 'react-admin';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HistoryIcon from '@material-ui/icons/History';
import BlockIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LaunchIcon from '@material-ui/icons/Launch';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ProjectListActions from './ProjectListActions';
import ProjectFilter from './ProjectFilter';
import ProjectBulkAction from './ProjectBulkAction';
import ProjectAvatar from '../components/ProjectAvatar';
import { getUrl, getFwUrl } from '../utils';

const ProjectField = ({ record }) => {
  if (!record || !record.id) return null;
  return (
    <ProjectAvatar
      id={record.id}
      name={record.name}
      address={record.address}
      logoUrl={record.logo_url}
    />
  );
};

const CodeField = ({ record }) => {
  if (!record || !record.id) return null;
  return (
    <Typography variant="overline" color="textSecondary">
      {record.code}
    </Typography>
  );
};

const DeleteOrArchivedField = ({ record }) => {
  if (!record || !record.id) {
    return null;
  }
  const { archived_at, deleted_at } = record;
  if (!archived_at && !deleted_at) {
    return (
      <Box component="div" title="Not archived or deleted">
        <CheckIcon />
      </Box>
    );
  }
  const datePrefix = archived_at ? 'Archived at' : 'Deleted at';
  const date = archived_at ? new Date(archived_at) : new Date(deleted_at);
  const dateString = date.toLocaleDateString();
  const iconProps = {
    color: 'error',
    fontSize: 'small',
  };
  return (
    <Box component="div" title={`${datePrefix} ${date}`} display="flex">
      {archived_at ? (
        <ArchiveIcon {...iconProps} />
      ) : (
        <DeleteForeverIcon {...iconProps} />
      )}
      <span>{dateString}</span>
    </Box>
  );
};

const BackupField = ({ record }) => {
  if (!record || !record.id) return null;
  const { _disable_backups, _last_backup } = record;
  if (_disable_backups) return <BlockIcon />;

  const momentDate = moment(_last_backup);
  const dateString = _last_backup
    ? momentDate.calendar()
    : ' Never backed up';
  const titleString = _last_backup
    ? momentDate.toString()
    : ' Never backed up';
  const iconProps = {
    color: 'secondary',
    fontSize: 'small',
  };
  return (
    <Box component="div" title={titleString} display="flex">
      <HistoryIcon {...iconProps} />
      <span style={{ marginLeft: 4 }}>{dateString}</span>
    </Box>
  );
};

const useStylesActionField = makeStyles({
  listItemIcon: {
    minWidth: 40,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
});
const ActionsField = ({ record }) => {
  const classes = useStylesActionField();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!record || !record.id) return null;
  const url = getUrl('projects', record.id);
  const fwUrl = getFwUrl(record.id);
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={url}>
          <ListItemIcon className={classes.listItemIcon}>
            <ArrowForwardIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Manage</Typography>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem component="a" href={fwUrl} target="_blank">
          <ListItemIcon className={classes.listItemIcon}>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Go to FieldWire</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 100, 500]} {...props} />
);

export const ProjectList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updated_at', order: 'DESC' }}
      actions={<ProjectListActions />}
      filters={<ProjectFilter />}
      bulkActionButtons={<ProjectBulkAction />}
      pagination={<ProjectPagination />}
    >
      <Datagrid>
        <ProjectField label="Name" />
        <CodeField label="Code" />
        <NumberField source="sheet_count" label="Sheets" />
        <NumberField source="user_count" label="Users" />
        <DateField source="created_at" />
        <DeleteOrArchivedField label="Active" />
        <BackupField label="Backup" />
        <ActionsField />
      </Datagrid>
    </List>
  );
};
